<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field type="text" filled label="Код" v-model="code"
                          hide-details
                          disabled
                          color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <v-checkbox type="text" filled
                        label="Фізична особа"
                        v-model="is_legal_person"
                        hide-details
                        color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Назва коротка" v-model="short_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="short_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Назва повна" v-model="full_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="full_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field type="text" filled
                          :label="is_legal_person ? 'Код ДРФО' : 'Код ЄДРПОУ'"
                          v-model="ident_code"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="ident_code ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field type="text" filled
                          label="Код платника ПДВ"
                          v-model="ipn_tax"
                          hide-details
                          color="grey"/>
          </v-col>
          <v-col cols="6" md="4">
            <v-checkbox type="text" filled
                        label="Покупець"
                        v-model="is_buyer"
                        hide-details
                        color="grey"/>
          </v-col>
          <v-col cols="6" md="4">
            <v-checkbox type="text" filled
                        label="Постачальник"
                        v-model="is_provider"
                        hide-details
                        color="grey"/>
          </v-col>
          <v-col cols="6" md="4">
            <v-checkbox type="text" filled
                        label="Кінцевий покупець"
                        v-model="is_end_buyer"
                        hide-details
                        color="grey"/>
          </v-col>
          <v-col cols="12">
            <v-textarea rows="2" hide-details color="grey" filled label="Фактична адреса" v-model="fact_address"/>
          </v-col>
          <v-col cols="12">
            <v-textarea rows="2" hide-details color="grey" filled label="Юридична адреса" v-model="legal_address"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" :loading="getModalLoading"
             @click="crud_item" class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_CONTRACTOR_LIST_ELEMENTS,
  UPDATE_CONTRACTOR_LIST_ELEMENTS,
  REMOVE_CONTRACTOR_LIST_ELEMENTS
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'contractor_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Contractor",
  mixins: [ModalComponentMixin],
  data() {
    return {
      short_name: this.item.short_name,
      full_name: this.item.full_name,
      code: this.item.code || '',
      ident_code: this.item.ident_code || '',
      is_legal_person: this.item.is_legal_person || false,
      ipn_tax: this.item.ipn_tax || '',
      fact_address: this.item.fact_address || '',
      legal_address: this.item.legal_address || '',
      is_buyer: this.item.is_buyer || false,
      is_provider: this.item.is_provider || false,
      is_end_buyer: this.item.is_end_buyer || false,
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.short_name = this.item.short_name
      this.full_name = this.item.full_name
      this.code = this.item.code
      this.ident_code = this.item.ident_code || ''
      this.is_legal_person = this.item.is_legal_person || false
      this.ipn_tax = this.item.ipn_tax || ''
      this.fact_address = this.item.fact_address || ''
      this.legal_address = this.item.legal_address || ''
      this.is_buyer = this.item.is_buyer || false
      this.is_provider = this.item.is_provider || false
      this.is_end_buyer = this.item.is_end_buyer || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення контрагента ${this.short_name}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      let error_text = `Коротка назва, повна назва, ${this.is_legal_person ? 'код ДРФО' : 'Код ЄДРПОУ'} - мають бути заповнені`
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: error_text,
          color: 'error lighten-1'
        })
        return
      }

      if (!this.is_buyer && !this.is_provider && !this.is_end_buyer) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Відмітьте, щонайменше одну із ознак (Покупець, Постачальник, Кінцевий покупець)',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        short_name: this.short_name,
        full_name: this.full_name,
        code: this.code,
        code_internationale: this.code_internationale,
        is_legal_person: this.is_legal_person || false,
        ident_code: this.ident_code || '',
        ipn_tax: this.ipn_tax || '',
        fact_address: this.fact_address || '',
        legal_address: this.legal_address || '',
        is_buyer: this.is_buyer || false,
        is_provider: this.is_provider || false,
        is_end_buyer: this.is_end_buyer || false
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_CONTRACTOR_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal({emit: true})
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_CONTRACTOR_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal({emit: true})
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.short_name = payload.short_name
              this.full_name = payload.full_name
              this.code = payload.code
              this.is_legal_person = payload.is_legal_person || false
              this.ipn_tax = payload.ipn_tax || ''
              this.ident_code = payload.ident_code || ''
              this.fact_address = payload.fact_address || ''
              this.legal_address = payload.legal_address || ''
              this.is_buyer = payload.is_buyer || false
              this.is_provider = payload.is_provider || false
              this.is_end_buyer = payload.is_end_buyer || false
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_CONTRACTOR_LIST_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal({emit: true})
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
}
</script>
